<template>
  <div class="filter-dashboard-analysis-cr">
    <span>
      <strong>Type: </strong>
      <span class="capitalize">{{ convertLowerCase(filters.type, true) }}</span>
    </span>
    <span>
      <strong>Source: </strong>
      <span class="capitalize">{{
        convertLowerCase(filters.source, false)
      }}</span>
    </span>
    <span>
      <strong>Date: </strong>
      <span class="capitalize">
        {{ convertLowerCase(filters.date_format, true) }}
      </span>
    </span>
  </div>
</template>
<script>
export default {
  name: "ShowFiltersAnalysisCR",
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  methods: {
    convertLowerCase(value, isLowerCase) {
      if (!value) return "All";
      let dvd = value.split(" ");
      if (dvd.length === 1) return isLowerCase ? value.toLowerCase() : value;
      return dvd.join(" ").toLowerCase();
    },
  },
};
</script>
<style scoped>
.filter-dashboard-analysis-cr {
  margin-top: 5px;
}
.filter-dashboard-analysis-cr > span:first-child {
  margin-inline-start: 0;
}
.filter-dashboard-analysis-cr > span {
  margin-inline-end: 10px;
  margin-inline-start: 10px;
  position: relative;
}

.filter-dashboard-analysis-cr span strong {
  font-weight: 900;
  text-transform: uppercase;
}
.filter-dashboard-analysis-cr > span > span {
  font-style: italic;
}
.filter-dashboard-analysis-cr > span > span.capitalize {
  text-transform: capitalize;
}
</style>