import { amgApi } from "@/service/axios";

class DashboardServices {
    async getRemovedJourney(params){
        try{
            return await amgApi.post('specialists/dashboard-specialists/dashboard-data-for-remove', params);
        }catch(error){
            throw error;
        }
    }

    async getAutomaticOptions(){
        try {
            const data = amgApi.get('specialists/dashboard-specialists/get-automatic-options');
            return data;
        } catch (error) {
            throw error;
        }
    }
    async getLeadsWithRemovedAccounts(params){
        try {
            const data = amgApi.post('specialists/dashboard-specialists/get-lead-with-accounts-removed', params);
            return data;
        } catch (error) {
            throw error;
        }
    }
    async getListAccountsByLeadId(params){
        try {
            const data = amgApi.post('specialists/dashboard-specialists/get-list-accounts-by-lead-id', params);
            return data;
        } catch (error) {
            throw error;
        }
    }
    async getManualOptions(){
        try {
            const data = amgApi.get('specialists/dashboard-specialists/get-manual-options');
            return data;
        } catch (error) {
            throw error;
        }
    }
}
export default new DashboardServices();
