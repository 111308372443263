<template>
  <div class="w-100">
    <h4 class="p-1 text-uppercase font-weight-bolder">{{ title }}</h4>
    <p>{{ paginationInfo }}</p>
    <b-input-group
      class="mr-1 my-1"
      slot="input"
    >
      <b-form-input
        id="list-leads-seach-input"
        v-model="inputSearchLeads.model"
        :type="inputSearchLeads.inputType"
        :placeholder="inputSearchLeads.placeholder"
        :class="inputSearchLeads.class"
        @keyup.enter="$emit('search', inputSearchLeads.model)"
      />
      <b-input-group-append>
        <b-button
          id="list-leads-search-icon"
          variant="primary"
          @click="$emit('search', inputSearchLeads.model)"
        >
          <feather-icon icon="SearchIcon" />
        </b-button>
      </b-input-group-append>
    </b-input-group>
    <b-list-group class="custom-list border overflow-hidden">
      <b-list-group-item
        v-for="(item, index) in data"
        :key="index"
        class="p-1 px-md-2 cursor-pointer d-flex justify-content-between align-items-center rounded-0"
        :class="leadSelected === JSON.stringify(item) ? 'item-selected' : null"
        @click="select(item)"
      >
        <div class="d-flex flex-wrap">
          <feather-icon icon="UserIcon" size="18" class="mr-1" />
          <div class="d-flex flex-column">
            <span class="value-item">{{ item.client_name }}</span>
            <span class="value-item">{{ item.created_at | myGlobalDay}}</span>
          </div>
        </div>
        <b-badge variant="primary" v-if="!!item.bureaus"
          >{{ badgeContent(item) }}
        </b-badge>
      </b-list-group-item>
    </b-list-group>
    <div
      class="rounded-lg mt-50 d-flex row flex-wrap justify-content-between align-items-center"
    >
      <div>
        <p>{{ paginationInfo }}</p>
      </div>
      <div>
        <b-pagination
          v-model="paginate.current_page"
          :total-rows="paginate.total_rows"
          :per-page="paginate.per_page"
          first-number
          last-number
        ></b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    inputSearchLeads:{
      required: false,
      type: Object,
      default: () => ({
        model: "",
        inputType: "text",
        placeholder: "Search by name...",
        class: "form-control",
      }),
    },
    title: {
      required: false,
      type: String,
      default: "Leads/Clients",
    },
    data: {
      required: true,
      type: Array,
      default: () => [],
    },
    paginate: {
      required: true,
      type: Object,
      default: () => ({
        last_page: 0,
        current_page: 0,
      }),
    },
    tab: String,
  },
  data() {
    return {
      leadSelected: null,
    };
  },
  mounted() {
    if (this.data.length > 0) this.select(this.data[0]);
  },
  methods: {
    select(item) {
      this.leadSelected = JSON.stringify({ ...item });
      const param = { ...item };
      this.$emit("selectedItem", param);
    },
    badgeContent(item) {
      let unit = this.tab == "removes" ? "item" : "account";
      unit = item.bureaus + " " + unit;
      unit = item.bureaus == 1 ? unit : unit + "s";
      return unit;
    },
  },
  computed: {
    paginationInfo() {
      const startIndex =
        (this.paginate.current_page - 1) * this.paginate.per_page + 1;
      const endIndex = Math.min(
        this.paginate.current_page * this.paginate.per_page,
        this.paginate.total_rows
      );
      return `Showing ${startIndex} to ${endIndex} of ${this.paginate.total_rows} entries`;
    },
  },
  watch: {
    "paginate.current_page": function (newValue) {
      this.$emit("selectedPage", newValue);
    },
  },
};
</script>
<style scoped>
.item-selected {
  background-color: #007deb !important;
  color: white !important;
}
.item-selected .value-item {
  font-weight: bolder !important;
}
</style>
