<template>
<div class="d-block w-100">
  <e-charts
    style="width: 100% !important"
    :key="keyChart"
    ref="line"
    auto-resize
    autoresize
    :options="option"
    @click="handleChartClick"
  />
  <b-row class="mt-2">
    <b-col cols="12" class="d-flex flex-wrap justify-content-end align-items-center">
      <div class="d-flex w-auto h-auto mr-1" v-for="(item, index) in legends" :key="index">
        <span class="square mr-1" :style="`background-color: ${item.color} !important;`"></span>
        <span class="text-capitalize font-size-md">{{ item.text | sanitizedText }}</span>
      </div>
    </b-col>
  </b-row>
</div>
</template>
<script>
import echarts from "echarts/lib/echarts";
import ECharts from "vue-echarts";

export default {
  components:{
      ECharts
  },
  props: {
    xAxisData:{
      type: Array,
      required: true,
      default: () => []
    },
    yAxisData: {
      type: Object,
      required: true,
      default: () => {}
    },
    colorsBar: {
      type: Array,
      required: false,
      default: () => ['#3FC600','#FF9900','#FD4C50','#3F7AFA','#E315DA','#F1C40F','#B2BABB','#4FC3F7','#8D6E63']
    }
  },
  data(){
    return {
      option: {
        xAxis: [
          {
            type: "category",
            axisTick: { show: false },
            data: [],
            axisLabel: {
              color: "#fff",
              margin: 14,
              fontSize: 11,
              interval: 0,
              rotate: 0
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            minInterval: 1,
            axisLabel: {
              margin: 20,
              fontSize: 14,
              color: "#fff",
            },
            axisLine: { show: true },
          },
        ],
        series: [
        ],
      },
      keyChart: 0,
      legends: []
    }
  },
  mounted(){
    this.generateChart();
    this.setTextColor();
  },
  methods:{
    generateChart(){
      // ====== data to xAxis ======= //
      this.option.xAxis[0].data = this.xAxisData.map(item => { return `${item.name.toUpperCase()} \n \n total:${item.total}` });

      // ===== data to yAxis ===== //
      // default obj to series
      const objData = {
        color: [],
        type: "bar",
        barGap: 0,
        label: {
          show: true,
          position: "top",
          distance: 12,
          align: "center",
          verticalAlign: "middle",
          rotate: 1,
          formatter: "{c}",
          fontSize: 12,
          color: this.isDarkSkin ? "#fff" : "#000",
        },
        itemStyle: {
          normal: {
            barBorderRadius: [7, 7, 0, 0],
          },
        },
        data: [],
        showBackground: true,
        backgroundStyle: {
          color: "rgba(220, 220, 220, 0.4)",
        },
      };
      // get legends
      const legends = Object.keys(this.yAxisData);
      this.legends = legends.map((item, index) => {
        return {
          color: this.colorsBar[index],
          text: item
        }
      })
      // get values to legends
      this.option.series = legends.map((element, index) => {
        const item = {...objData};
        item.color = this.colorsBar[index];
        item.data = this.yAxisData[element];
        return item;
      });
    },
    setTextColor(){
      this.option.series.forEach(element => {
        element.label.color = this.isDarkSkin ? "#fff" : "#000";
      });
      this.option.xAxis[0].axisLabel.color = this.isDarkSkin ? "#fff" : "#000";
      this.option.yAxis[0].axisLabel.color = this.isDarkSkin ? "#fff" : "#000";
    },
    handleChartClick({dataIndex, componentIndex}){
      const params = {
        xAxisIndex: dataIndex,
        yAxisIndex: componentIndex
      };
      this.$emit('selectItemChart', params);
    }
  },
  watch:{
    isDarkSkin(newValue){
      this.setTextColor();
    }
  },
  filters:{
    sanitizedText(value){
      const newValue = value ? value.replace(/[-_]/g,' '): value;
      return newValue.toLowerCase();
    }
  }
}
</script>
<style scoped>
.square{
  display: flex !important;
  width: 20px;
  height: 20px;
  border-radius: 5px !important;
}
.font-size-md{
  font-size: 15px;
}
</style>
