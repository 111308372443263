<template>
  <b-dropdown
    v-model="showPicker"
    right
    toggle-class="text-decoration-none p-0 rounded-lg"
    class="w-100"
    no-caret
    variant="link"
    ref="dropdown"
  >
    <template #button-content>
      <b-form-input
        v-model="selectedDate"
        :readonly="!isLoading"
        :disabled="isLoading"
        :class="!isLoading ? 'bg-transparent' : null"
      >
        <template #append>
          <feather-icon icon="CalendarIcon" />
        </template>
      </b-form-input>
    </template>
    <b-dropdown-header id="dropdown-header-label" class="text-center py-50">
      <feather-icon icon="CalendarIcon" size="18" class="mr-50" />
      <span class="font-size-md text-uppercase"
        >{{ selectedMonthName }}, {{ selectedYear }}</span
      >
    </b-dropdown-header>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-form class="date-picker-dropdown">
      <b-tabs align="center" pills class="px-2 pb-2">
        <b-tab title="Monthly">
          <b-row>
            <b-col
              v-for="(month, index) in months"
              :key="index"
              sm="3"
              class="p-50"
            >
              <b-button
                block
                :variant="
                  selectedMonth === month.value
                    ? 'outline-primary'
                    : 'outline-light'
                "
                :class="
                  !isDarkSkin && selectedMonth != month.value
                    ? 'text-dark'
                    : null
                "
                class="d-flex justify-content-center align-items-center"
                @click="setSelectedMonth(month.value)"
              >
                {{ month.text }}
              </b-button>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Yearly">
          <b-row>
            <b-col
              v-for="(year, index) in years"
              :key="index"
              sm="3"
              class="p-50"
            >
              <b-button
                block
                :variant="
                  selectedYear === year.value
                    ? 'outline-primary'
                    : 'outline-light'
                "
                :class="
                  !isDarkSkin && selectedYear != year.value ? 'text-dark' : null
                "
                @click="setSelectedYear(year.value)"
              >
                {{ year.text }}
              </b-button>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-dropdown-form>
  </b-dropdown>
</template>

<script>
import moment from "moment";
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const currentYear = new Date().getFullYear();
    const years = Array.from(
      { length: currentYear - 2024 + 1 },
      (_, i) => 2024 + i
    ).map((year) => ({ text: year, value: year }));

    return {
      selectedMonth: new Date().getMonth() + 1,
      selectedYear: new Date().getFullYear(),
      selectedDate: "",
      showPicker: false,
      months: moment
        .months()
        .map((month, index) => ({ text: month, value: index + 1 })),
      years: years,
      dateSelected: [],
    };
  },
  computed: {
    selectedMonthName() {
      let index = this.selectedMonth ? this.selectedMonth : 0;
      return this.months[index].text;
    },
  },
  methods: {
    setSelectedMonth(month) {
      this.selectedMonth = month;
      this.updateSelectedDate();
    },
    setSelectedYear(year) {
      this.selectedYear = year;
      this.updateSelectedDate();
    },
    updateSelectedDate() {
      this.selectedDate = `${this.selectedMonthName}, ${this.selectedYear}`;
      this.dateSelected = {
        month: this.selectedMonth,
        year: this.selectedYear,
      };
      this.$emit("dateSelected", this.dateSelected);
    },
    onClick() {
      this.$refs.dropdown.hide(true);
    },
    refreshDate() {
      this.selectedMonth = new Date().getMonth() + 1;
      this.selectedYear = new Date().getFullYear();
      this.updateSelectedDate();
    },
  },
  created() {
    this.months.unshift({
      text: "All",
      value: null,
    });
    this.updateSelectedDate();
  },
};
</script>

<style scoped>
.date-picker-dropdown {
  width: 480px;
}
.font-size-md {
  font-size: 14px !important;
}
.nav-link-active {
  border-radius: 30px !important;
  overflow: hidden;
}
.btn-outline-light:hover {
  border: 1px solid #0090e7 !important;
  background: transparent !important;
  color: #0090e7 !important;
}
</style>
