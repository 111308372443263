<template>
  <div class="p-1 rounded-lg" :class="isDarkSkin ? 'bg-dark' : 'bg-white'">
    <b-row class="px-1" align-v="center">
      <b-col cols="12" md="4" xl="3">
        <div class="rounded-lg">
          <b-form-group class="font-weight-bolder">
            <template #label> Type </template>
            <v-select
              v-model="typeNcr"
              :clearable="true"
              label="label"
              :options="typeNcrOptions"
              placeholder="--Select--"
              :reduce="(option) => option.value"
              :disabled="isLoading"
            />
          </b-form-group>
        </div>
      </b-col>
      <b-col cols="12" md="4" xl="3">
        <b-form-group class="font-weight-bolder">
          <template #label> Source </template>
          <v-select
            v-model="sourceOption"
            :clearable="false"
            label="name"
            :options="sourceOptions"
            placeholder="--Select--"
            :reduce="(option) => option.id"
            :disabled="isLoading"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" xl="3">
        <b-form-group class="font-weight-bolder">
          <template #label> Date </template>
          <DatePicker
            :isLoading="isLoading"
            ref="datePicker"
            @dateSelected="handleDateUpdated"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4" xl="3">
        <b-button variant="primary" @click="updateAll()" class="w-100">
          <feather-icon icon="RefreshCcwIcon" class="mr-1" /> REFRESH
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import RemoveJourneyServices from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/RemoveJourney/services/removeJourney.services";
import DatePicker from "@/views/specialists/sub-modules/financial-approval/views/dashboard/component/commons/DatePicker.vue";
export default {
  components: {
    DatePicker,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      typeNcr: null,
      typeNcrOptions: [
        { label: "MANUAL", value: "MANUAL" },
        { label: "AUTOMATIC", value: "AUTOMATIC" },
      ],
      sourceOption: null,
      sourceOptions: [],
      automaticOptions: [],
      manualOptions: [],
      dateSelected: [],
      source: null,
    };
  },
  methods: {
    async getManualOptions() {
      try {
        const { data } = await RemoveJourneyServices.getManualOptions();
        this.manualOptions = data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getAutomaticOptions() {
      try {
        const { data } = await RemoveJourneyServices.getAutomaticOptions();
        this.automaticOptions = data;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    handleDateUpdated(date) {
      this.dateSelected = date;
    },
    sendValueFilters() {
      this.selectedNameSource();
      let month = this.dateSelected.month == null ? "All" : new Date(
        this.dateSelected.year,
        this.dateSelected.month - 1
      ).toLocaleString("default", { month: "long" });
      const params = {
        type: this.typeNcr,
        sourceOption: this.sourceOption,
        date: this.dateSelected,
        source: this.source,
        date_format: `${month}, ${this.dateSelected.year}`,
      };
      this.$emit("selectedFilters", params);
    },
    updateAll() {
      this.sourceOption = null;
      this.typeNcr = null;
      this.$emit("updateService");
      this.$refs["datePicker"].refreshDate();
    },
    selectedNameSource() {
      if (this.sourceOption) {
        this.source = this.sourceOptions.find(
          (x) => x.id == this.sourceOption
        ).name;
      } else {
        this.source = null;
      }
    },
  },
  async mounted() {
    await this.getAutomaticOptions();
    await this.getManualOptions();
  },
  watch: {
    typeNcr: function (newVal) {
      if (this.isLoading) return;
      this.sourceOption = null;

      let sourceOptions = [];
      if (newVal == "MANUAL") {
        sourceOptions = this.manualOptions;
      } else if (newVal == "AUTOMATIC") {
        sourceOptions = this.automaticOptions;
      }
      this.sourceOptions = sourceOptions;
      this.sendValueFilters();
    },
    sourceOption(value) {
      if (value) {
        this.source = this.sourceOptions.find((x) => x.id == value).name;
      } else {
        this.source = null;
      }
      if (this.isLoading) return;
      this.sendValueFilters();
    },
    dateSelected() {
      if (this.isLoading) return;
      this.sendValueFilters();
    },
    isDarkSkin() {
      const params = {
        type: this.typeNcr,
        sourceOption: this.sourceOption,
        date: this.dateSelected,
      };
      this.$emit("selectedFilters", params);
    },
  },
};
</script>
